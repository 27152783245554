module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/assets/images/jointacademy-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f1e95e27b5a2ceba277579163c789d5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"mD6zHWpMkp7Bga6YdEFBpOrM1OQQH6aR","devKey":"mD6zHWpMkp7Bga6YdEFBpOrM1OQQH6aR","trackPage":false,"trackPageImmediately":false,"trackPageOnlyIfReady":false,"trackPageOnRouteUpdate":false,"trackPageWithTitle":false,"delayLoad":false,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
